import React, { Component } from 'react'
import { css } from 'emotion'

class CFInput extends Component {
  render() {
    const {
      value,
      onChange,
      onKeyPress,
      maxLength,
      multiline,
      forwardedRef,
      style: customStyle,
      className: customClassName,
      ...props
    } = this.props

    // 1. Apply base style
    const style = [styles.baseStyle]

    // 2. Apply prop styles
    const keys = Object.keys(props)
    for (const key of keys) {
      if (props[key] === true && styles[key]) {
        style.push(styles[key])
      }

      switch (key) {
        case 'mh':
          style.push(css({ marginLeft: props[key], marginRight: props[key] }))
          break
        case 'mv':
          style.push(css({ marginTop: props[key], marginBottom: props[key] }))
          break
        case 'mt':
          style.push(css({ marginTop: props[key] }))
          break
        case 'mb':
          style.push(css({ marginBottom: props[key] }))
          break
        case 'ml':
          style.push(css({ marginLeft: props[key] }))
          break
        case 'mr':
          style.push(css({ marginRight: props[key] }))
          break
        case 'm':
          style.push(css({ margin: props[key] }))
          break
        case 'ph':
          style.push(css({ paddingLeft: props[key], paddingRight: props[key] }))
          break
        case 'pv':
          style.push(css({ paddingTop: props[key], paddingBottom: props[key] }))
          break
        case 'pt':
          style.push(css({ paddingTop: props[key] }))
          break
        case 'pb':
          style.push(css({ paddingBottom: props[key] }))
          break
        case 'pl':
          style.push(css({ paddingLeft: props[key] }))
          break
        case 'pr':
          style.push(css({ paddingRight: props[key] }))
          break
        case 'p':
          style.push(css({ padding: props[key] }))
          break
        case 'w':
          style.push(css({ width: props[key] }))
          break
        case 'maxWidth':
          style.push(css({ maxWidth: props[key] }))
          break
        case 'minWidth':
          style.push(css({ minWidth: props[key] }))
          break
        case 'h':
          style.push(css({ height: props[key] }))
          break
        case 'minHeight':
          style.push(css({ minHeight: props[key] }))
          break
        case 'maxHeight':
          style.push(css({ maxHeight: props[key] }))
          break
        case 'bg':
          style.push(css({ backgroundColor: props[key] }))
          break
        case 'br':
          style.push(css({ borderRadius: props[key] }))
          break
        case 'bc':
          style.push(css({ border: 'solid', borderColor: props[key] }))
          break
        case 'bw':
          style.push(css({ borderWidth: props[key] }))
          break
        case 'bbw':
          style.push(css({ borderBottomWidth: props[key] }))
          break
        case 'btw':
          style.push(css({ borderTopWidth: props[key] }))
          break
        case 'blw':
          style.push(css({ borderLeftWidth: props[key] }))
          break
        case 'brw':
          style.push(css({ borderRightWidth: props[key] }))
          break
        case 'top':
          style.push(css({ top: props[key] }))
          break
        case 'bottom':
          style.push(css({ bottom: props[key] }))
          break
        case 'left':
          style.push(css({ left: props[key] }))
          break
        case 'right':
          style.push(css({ right: props[key] }))
          break
        case 'color':
          style.push(css({ color: props[key] }))
          break
        case 'fontSize':
          style.push(css({ fontSize: props[key] }))
          break
        case 'boxshadow':
          style.push(css({ boxShadow: props[key] }))
          break
        case 'placeholderstyle':
          style.push(css({ '&::placeholder': props[key] }))
          break
        default:
          break
      }
    }

    // 3. Apply customStyle passed down as this.props.style
    style.push(customStyle)
    style.push(customClassName)

    return (
      <>
        {multiline ? (
          <textarea
            ref={forwardedRef}
            className={css(style)}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            maxLength={maxLength}
            {...props}
          />
        ) : (
          <input
            ref={forwardedRef}
            className={css(style)}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            maxLength={maxLength}
            {...props}
          />
        )}
      </>
    )
  }
}

const styles = {
  baseStyle: css({
    outline: 0,
    border: 'none',
    fontSize: 18,
    fontFamily: 'roboto',
    backgroundColor: 'transparent',
    resize: 'vertical',
  }),
  block: css({
    display: 'block',
  }),
  inlineBlock: css({
    display: 'inline-block',
  }),
  relative: css({
    position: 'relative',
  }),
  absolute: css({
    position: 'absolute',
  }),
  h1: css({
    fontSize: 36,
  }),
  h2: css({
    fontSize: 24,
  }),
  h3: css({
    fontSize: 20,
  }),
  h4: css({
    fontSize: 18,
  }),
  h5: css({
    fontSize: 16,
  }),
  h6: css({
    fontSize: 14,
  }),
  h7: css({
    fontSize: 12,
  }),
}

export default React.forwardRef((props, ref) => {
  return <CFInput forwardedRef={ref} {...props} />
})
