import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Spinner extends Component {
  render() {
    return (
      <FontAwesomeIcon
        icon="spinner"
        size="6x"
        spin
        color={this.props.color || 'white'}
      />
    )
  }
}
