import React, { Component } from 'react'
import { css } from 'emotion'

export default class HorizontalLine extends Component {
  render() {
    const { className, margin, color } = this.props
    const classNames = css(styles.container, className)
    return (
      <div
        style={{
          background: color,
          marginLeft: margin,
          marginRight: margin,
        }}
        className={classNames}
      />
    )
  }
}

const styles = {
  container: css({
    height: 1,
    background: 'white',
    alignSelf: 'stretch',
  }),
}
