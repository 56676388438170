"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isCoordinateInDeliveryZone = exports.getIsProductActive = exports.getIsOpenForGivenTime = exports.moment = exports.parseRewardInfo = exports.getIsRewardRedeemable = exports.getIsRewardValid = exports.formatPhoneNumber = exports.removeNonNumericString = exports.parseCurrency = void 0;

var _momentTimezone = _interopRequireDefault(require("moment-timezone"));

var _lodash = require("lodash");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var parseCurrency = function parseCurrency(amount) {
  var amountNumber;

  if (amount == null) {
    return 0;
  }

  if (typeof amount === 'string') {
    amountNumber = Number(amount);
  } else {
    amountNumber = amount;
  }

  if (isNaN(amountNumber)) {
    // console.warn('amount is not a valid number')
    return 0;
  }

  return Math.round(amountNumber * Math.pow(10, 2)) / Math.pow(10, 2);
};

exports.parseCurrency = parseCurrency;

var removeNonNumericString = function removeNonNumericString(str) {
  if (!str) {
    return '';
  }

  return str.replace(/\D+/g, '');
};

exports.removeNonNumericString = removeNonNumericString;

var formatPhoneNumber = function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '';
  }

  var phoneNumberStr = phoneNumber + '';
  return removeNonNumericString(phoneNumberStr).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3');
}; // Checks if given reward is currently valid


exports.formatPhoneNumber = formatPhoneNumber;

var getIsRewardValid = function getIsRewardValid(_ref) {
  var _ref$isLoggedIn = _ref.isLoggedIn,
      isLoggedIn = _ref$isLoggedIn === void 0 ? false : _ref$isLoggedIn,
      rewardDetails = _ref.rewardDetails,
      _ref$isFirstOrder = _ref.isFirstOrder,
      isFirstOrder = _ref$isFirstOrder === void 0 ? false : _ref$isFirstOrder,
      productsDetails = _ref.productsDetails,
      cartSubTotal = _ref.cartSubTotal,
      _ref$redeemedRewardCo = _ref.redeemedRewardCount,
      redeemedRewardCount = _ref$redeemedRewardCo === void 0 ? 0 : _ref$redeemedRewardCo,
      _ref$userPointsWithPr = _ref.userPointsWithPromoApplied,
      userPointsWithPromoApplied = _ref$userPointsWithPr === void 0 ? 0 : _ref$userPointsWithPr;
  var ERROR = {
    NOT_LOGGED_IN: {
      valid: false,
      message: 'Please log in before redeeming rewards'
    },
    NOT_ENOUGH_POINTS: {
      valid: false,
      message: 'Not Enough Points'
    },
    FREE_REWARD_EMPTY_CART: {
      valid: false,
      message: 'Free Reward cannot be redeemed with empty cart'
    },
    DISCOUNT_EMPTY_CART: {
      valid: false,
      message: 'Discount cannot be redeemed with empty cart'
    },
    INVALID_PRODUCT: {
      valid: false,
      message: 'Product is Invalid'
    },
    PRODUCT_SOLDOUT: {
      valid: false,
      message: 'Product is temporarily Out Of Stock'
    },
    MAXIMUM_COUNT: {
      valid: false,
      message: 'You cannot redeem any more of this reward'
    },
    INVALID_FIELD: {
      valid: false,
      message: 'This Reward cannot be redeemed'
    },
    INVALID_COUNT: {
      valid: false,
      message: 'You must redeem 1 or more'
    },
    INVALID_SUBTOTAL: {
      valid: false,
      message: 'SubTotal cannot be negative'
    }
  };

  if (!isLoggedIn) {
    return ERROR.NOT_LOGGED_IN;
  }

  if (redeemedRewardCount <= 0) {
    return ERROR.INVALID_COUNT;
  }

  if (cartSubTotal < 0) {
    return ERROR.INVALID_SUBTOTAL;
  }

  if (isFirstOrder && rewardDetails.requiredPoints === 0 && cartSubTotal <= 0) {
    return ERROR.FREE_REWARD_EMPTY_CART;
  }

  if (rewardDetails.discountSubtotal > 0 && cartSubTotal <= 0) {
    return ERROR.DISCOUNT_EMPTY_CART;
  }

  if (redeemedRewardCount > rewardDetails.maximumCount) {
    return ERROR.MAXIMUM_COUNT;
  }

  if (rewardDetails.productId && !productsDetails[rewardDetails.productId]) {
    return ERROR.INVALID_PRODUCT;
  }

  if (rewardDetails.productId && !getIsProductActive(productsDetails[rewardDetails.productId])) {
    return ERROR.PRODUCT_SOLDOUT;
  }

  if (userPointsWithPromoApplied < 0) {
    return ERROR.NOT_ENOUGH_POINTS;
  }

  return {
    valid: true
  };
}; // Checks if given reward is redeemable.


exports.getIsRewardValid = getIsRewardValid;

var getIsRewardRedeemable = function getIsRewardRedeemable(_ref2) {
  var _ref2$isLoggedIn = _ref2.isLoggedIn,
      isLoggedIn = _ref2$isLoggedIn === void 0 ? false : _ref2$isLoggedIn,
      rewardDetails = _ref2.rewardDetails,
      _ref2$isFirstOrder = _ref2.isFirstOrder,
      isFirstOrder = _ref2$isFirstOrder === void 0 ? false : _ref2$isFirstOrder,
      productsDetails = _ref2.productsDetails,
      cartSubTotal = _ref2.cartSubTotal,
      _ref2$redeemedRewardC = _ref2.redeemedRewardCount,
      redeemedRewardCount = _ref2$redeemedRewardC === void 0 ? 0 : _ref2$redeemedRewardC,
      _ref2$userPointsWithP = _ref2.userPointsWithPromoApplied,
      userPointsWithPromoApplied = _ref2$userPointsWithP === void 0 ? 0 : _ref2$userPointsWithP;
  return getIsRewardValid({
    isLoggedIn: isLoggedIn,
    rewardDetails: rewardDetails,
    isFirstOrder: isFirstOrder,
    productsDetails: productsDetails,
    cartSubTotal: cartSubTotal,
    redeemedRewardCount: redeemedRewardCount + 1,
    userPointsWithPromoApplied: userPointsWithPromoApplied - rewardDetails.requiredPoints
  });
}; // Parses reward info


exports.getIsRewardRedeemable = getIsRewardRedeemable;

var parseRewardInfo = function parseRewardInfo(_ref3) {
  var rewardInfo = _ref3.rewardInfo,
      _ref3$isFirstOrder = _ref3.isFirstOrder,
      isFirstOrder = _ref3$isFirstOrder === void 0 ? false : _ref3$isFirstOrder,
      _ref3$productsDetails = _ref3.productsDetails,
      productsDetails = _ref3$productsDetails === void 0 ? {} : _ref3$productsDetails,
      count = _ref3.count;
  var type = rewardInfo.type,
      value = rewardInfo.value,
      points = rewardInfo.points,
      imageUrl = rewardInfo.imageUrl;
  var rewardDetails = {
    // Descriptions
    name: null,
    imageUrl: imageUrl,
    maximumCount: 100,
    // WITH
    requiredPoints: null,
    totalRequiredPoints: null,
    // FOR
    discountSubtotal: null,
    totalDiscountSubtotal: null,
    productId: null,
    price: null,
    rewardValue: null,
    totalRewardValue: null // Type Follows With/For/Condition pattern
    // What are you redeeming WITH, what are you redeeming FOR, and in what CONDITION can you redeem it.
    // For instance, Free/Product/Birthday will indicate free product on birthday

  };

  switch (type) {
    case 'FreeFirstOrderOrPoints/Discount':
      {
        rewardDetails.name = "$".concat(value, " OFF");
        var discount = parseFloat(value) || 0;
        rewardDetails.discountSubtotal = discount;
        rewardDetails.totalDiscountSubtotal = discount * count;
        rewardDetails.rewardValue = discount;
        rewardDetails.totalRewardValue = discount * count;

        if (isFirstOrder) {
          rewardDetails.requiredPoints = 0;
          rewardDetails.totalRequiredPoints = 0;
          rewardDetails.maximumCount = 1;
        } else {
          rewardDetails.requiredPoints = points;
          rewardDetails.totalRequiredPoints = points * count;
        }
      }
      break;

    case 'Points/Discount':
      {
        rewardDetails.name = "$".concat(value, " OFF");

        var _discount = parseFloat(value) || 0;

        rewardDetails.discountSubtotal = _discount;
        rewardDetails.totalDiscountSubtotal = _discount * count;
        rewardDetails.rewardValue = _discount;
        rewardDetails.totalRewardValue = _discount * count;
        rewardDetails.requiredPoints = points;
        rewardDetails.totalRequiredPoints = points * count;
      }
      break;

    case 'FreeFirstOrderOrPoints/Product':
      {
        var productDetails = productsDetails[value];

        if (productDetails) {
          rewardDetails.productId = productDetails.id;
          rewardDetails.name = productDetails.name;
          rewardDetails.price = productDetails.price;
          rewardDetails.rewardValue = productDetails.price;
          rewardDetails.totalRewardValue = productDetails.price * count;
        }

        if (isFirstOrder) {
          rewardDetails.requiredPoints = 0;
          rewardDetails.totalRequiredPoints = 0;
          rewardDetails.maximumCount = 1;
        } else {
          rewardDetails.requiredPoints = points;
          rewardDetails.totalRequiredPoints = points * count;
        }
      }
      break;

    case 'Points/Product':
      {
        var _productDetails = productsDetails[value];

        if (_productDetails) {
          rewardDetails.productId = _productDetails.id;
          rewardDetails.name = _productDetails.name;
          rewardDetails.price = _productDetails.price;
          rewardDetails.rewardValue = _productDetails.price;
          rewardDetails.totalRewardValue = _productDetails.price * count;
        }

        rewardDetails.requiredPoints = points;
        rewardDetails.totalRequiredPoints = points * count;
      }
      break;
  }

  return rewardDetails;
};

exports.parseRewardInfo = parseRewardInfo;

var moment = function moment() {
  return _momentTimezone.default.apply(void 0, arguments).tz('America/Vancouver');
};

exports.moment = moment;

var getIsOpenForGivenTime = function getIsOpenForGivenTime(_ref4) {
  var hours = _ref4.hours,
      targetDate = _ref4.targetDate,
      time = _ref4.time,
      waitTime = _ref4.waitTime;
  var weekday = targetDate.weekday(); // get time from hours

  var open = (0, _lodash.get)(hours[weekday], 'open');
  var close = (0, _lodash.get)(hours[weekday], 'close');

  if (!open || !close) {
    return false;
  }

  var openMoment = moment(open);
  var closeMoment = moment(close);
  var openTime = moment(targetDate).hours(openMoment.hours()).minutes(openMoment.minutes()).seconds(0);
  var closeTime = moment(targetDate).hours(closeMoment.hours()).minutes(closeMoment.minutes()).seconds(0);
  var orderCloseMoment = moment(closeTime).subtract(waitTime, 'minutes');

  if (openTime.isSame(closeTime)) {
    return false;
  }

  var todayOrderClose;

  if (openTime.isBefore(closeTime)) {
    todayOrderClose = orderCloseMoment;
  } else {
    todayOrderClose = moment(orderCloseMoment).add(1, 'days');
  }

  return time.isBetween(openTime, todayOrderClose);
};

exports.getIsOpenForGivenTime = getIsOpenForGivenTime;

var getIsProductActive = function getIsProductActive(productDetails) {
  if (!productDetails) {
    // Product doens't exist
    return false;
  } // Since Dec 16 2018, active property is deprecated in favor of activeAfter


  var activeAfter = (0, _lodash.get)(productDetails, 'activeAfter');

  if (activeAfter) {
    var activeAfterMoment = moment(activeAfter);

    if (moment().isAfter(activeAfterMoment)) {
      return true;
    } else {
      return false;
    }
  }

  var active = (0, _lodash.get)(productDetails, 'active'); // If active property doesn't exist, this means product is active

  if (active === undefined || active === true) {
    return true;
  }

  if (active === false) {
    return false;
  } // As a fallback


  return true;
};

exports.getIsProductActive = getIsProductActive;

var isCoordinateInDeliveryZone = function isCoordinateInDeliveryZone(coordinate, polygon) {
  var x = coordinate.lat;
  var y = coordinate.lng;
  var inside = false;

  for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    var xi = parseFloat(polygon[i].lat);
    var yi = parseFloat(polygon[i].lng);
    var xj = parseFloat(polygon[j].lat);
    var yj = parseFloat(polygon[j].lng);
    var intersect = yi > y !== yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

exports.isCoordinateInDeliveryZone = isCoordinateInDeliveryZone;