import React from 'react'

export default class CFFBSubscribe extends React.Component {
  state = {
    loading: true,
    data: null,
    error: null,
  }
  componentDidMount() {
    const { componentDidMount, doc, onSnapshot, children, onError } = this.props
    componentDidMount && componentDidMount()
    this.setState({ loading: true })
    if (doc) {
      this.unsubscribeDoc = doc.onSnapshot(
        snapshot => {
          let data
          if (snapshot.data) {
            data = snapshot.data()
            data.id = snapshot.id
          } else if (snapshot.size > 0) {
            data = {}
            snapshot.forEach(doc => {
              data[doc.id] = doc.data()
              data[doc.id].id = doc.id
            })
          }
          onSnapshot && onSnapshot(data)
          if (children) {
            this.setState({ data, loading: false })
          }
        },
        error => {
          onError && onError(error)
          if (children) {
            this.setState({ error, loading: false })
          }
        }
      )
    }
  }
  componentWillUnmount() {
    this.unsubscribeDoc && this.unsubscribeDoc()
    this.unsubscribeDocs && this.unsubscribeDocs()
  }
  render() {
    if (this.props.children) {
      return this.props.children(this.state)
    }
    return null
  }
}
