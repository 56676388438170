import React from 'react'

export default function Space(props) {
  const { height, width, ...rest } = props
  const style = Object.keys(rest).reduce((prev, key) => {
    if (styles[key]) {
      prev = { ...prev, ...styles[key] }
    }
    return prev
  }, {})
  return <div style={{ height, width, ...style }} />
}

const styles = {
  h1: {
    height: '1rem',
  },
  h2: {
    height: '2rem',
  },
  h3: {
    height: '3rem',
  },
  w1: {
    width: '1rem',
  },
  w2: {
    width: '2rem',
  },
  w3: {
    width: '3rem',
  },
}
