import { db } from 'cf-core/src/config/firebase'

export function createRequest(restaurantId, locationId, requestDetails) {
  return db
    .collection('Restaurants')
    .doc(restaurantId)
    .collection('Locations')
    .doc(locationId)
    .collection('Requests')
    .add(requestDetails)
}
