import React from 'react'

export default class PromiseResolve extends React.Component {
  state = {
    loading: true,
    data: null,
    error: null,
  }
  componentDidMount() {
    const { componentDidMount, onThen, onCatch, promise } = this.props
    componentDidMount && componentDidMount()
    this.setState({ loading: true })
    if (promise) {
      promise
        .then(data => {
          onThen && onThen(data)
          this.setState({ data, loading: false })
        })
        .catch(error => {
          onCatch && onCatch(error)
          this.setState({ error, loading: false })
        })
    }
  }
  componentWillUnmount() {
    const { componentWillUnmount } = this.props
    componentWillUnmount && componentWillUnmount()
  }
  render() {
    if (this.props.children) {
      return this.props.children(this.state)
    }
    return null
  }
}
