import React, { Component } from 'react'
import { css } from 'emotion'

const MATCHES = {
  maxXS: window.matchMedia('(max-width: 375px)'),
  maxSM: window.matchMedia('(max-width: 576px)'),
  maxMD: window.matchMedia('(max-width: 767px)'),
  maxLG: window.matchMedia('(max-width: 992px)'),
  maxXL: window.matchMedia('(max-width: 1200px)'),
}

export default class CFView extends Component {
  state = {}
  componentDidMount() {
    const {
      maxXSStyle,
      maxSMStyle,
      maxMDStyle,
      maxLGStyle,
      maxXLStyle,
    } = this.props
    if (maxXSStyle) {
      this._onMatchXS(MATCHES.maxXS)
      MATCHES.maxXS.addListener(this._onMatchXS)
    }
    if (maxSMStyle) {
      this._onMatchSM(MATCHES.maxSM)
      MATCHES.maxSM.addListener(this._onMatchSM)
    }
    if (maxMDStyle) {
      this._onMatchMD(MATCHES.maxMD)
      MATCHES.maxMD.addListener(this._onMatchMD)
    }
    if (maxLGStyle) {
      this._onMatchLG(MATCHES.maxLG)
      MATCHES.maxLG.addListener(this._onMatchLG)
    }
    if (maxXLStyle) {
      this._onMatchXL(MATCHES.maxXL)
      MATCHES.maxXL.addListener(this._onMatchXL)
    }
  }
  componentWillUnmount() {
    MATCHES.maxXS.removeListener(this._onMatchXS)
    MATCHES.maxSM.removeListener(this._onMatchSM)
    MATCHES.maxMD.removeListener(this._onMatchMD)
    MATCHES.maxLG.removeListener(this._onMatchLG)
    MATCHES.maxXL.removeListener(this._onMatchXL)
  }
  render() {
    const {
      children,
      style: customStyle,
      className: customClassName,
      maxXSStyle,
      maxSMStyle,
      maxMDStyle,
      maxLGStyle,
      maxXLStyle,
      onClick,
      disabled,
      ...props
    } = this.props

    // 1. Apply base style
    const style = [styles.baseStyle]

    // 2. Apply prop styles
    const keys = Object.keys(props)
    for (const key of keys) {
      if (props[key] === true && styles[key]) {
        style.push(styles[key])
      }

      switch (key) {
        case 'mh':
          style.push(css({ marginLeft: props[key], marginRight: props[key] }))
          break
        case 'mv':
          style.push(css({ marginTop: props[key], marginBottom: props[key] }))
          break
        case 'mt':
          style.push(css({ marginTop: props[key] }))
          break
        case 'mb':
          style.push(css({ marginBottom: props[key] }))
          break
        case 'ml':
          style.push(css({ marginLeft: props[key] }))
          break
        case 'mr':
          style.push(css({ marginRight: props[key] }))
          break
        case 'm':
          style.push(css({ margin: props[key] }))
          break
        case 'ph':
          style.push(css({ paddingLeft: props[key], paddingRight: props[key] }))
          break
        case 'pv':
          style.push(css({ paddingTop: props[key], paddingBottom: props[key] }))
          break
        case 'pt':
          style.push(css({ paddingTop: props[key] }))
          break
        case 'pb':
          style.push(css({ paddingBottom: props[key] }))
          break
        case 'pl':
          style.push(css({ paddingLeft: props[key] }))
          break
        case 'pr':
          style.push(css({ paddingRight: props[key] }))
          break
        case 'p':
          style.push(css({ padding: props[key] }))
          break
        case 'w':
          style.push(css({ width: props[key] }))
          break
        case 'maxWidth':
          style.push(css({ maxWidth: props[key] }))
          break
        case 'minWidth':
          style.push(css({ minWidth: props[key] }))
          break
        case 'h':
          style.push(css({ height: props[key] }))
          break
        case 'minHeight':
          style.push(css({ minHeight: props[key] }))
          break
        case 'maxHeight':
          style.push(css({ maxHeight: props[key] }))
          break
        case 'bg':
          style.push(css({ backgroundColor: props[key] }))
          break
        case 'br':
          style.push(css({ borderRadius: props[key] }))
          break
        case 'btlr':
          style.push(css({ borderTopLeftRadius: props[key] }))
          break
        case 'btrr':
          style.push(css({ borderTopRightRadius: props[key] }))
          break
        case 'bc':
          style.push(css({ border: 'solid', borderColor: props[key] }))
          break
        case 'bw':
          style.push(css({ borderWidth: props[key] }))
          break
        case 'bbw':
          style.push(css({ borderBottomWidth: props[key] }))
          break
        case 'btw':
          style.push(css({ borderTopWidth: props[key] }))
          break
        case 'blw':
          style.push(css({ borderLeftWidth: props[key] }))
          break
        case 'brw':
          style.push(css({ borderRightWidth: props[key] }))
          break
        case 'top':
          style.push(css({ top: props[key] }))
          break
        case 'bottom':
          style.push(css({ bottom: props[key] }))
          break
        case 'left':
          style.push(css({ left: props[key] }))
          break
        case 'right':
          style.push(css({ right: props[key] }))
          break
        case 'color':
          style.push(css({ color: props[key] }))
          break
        case 'fontSize':
          style.push(css({ fontSize: props[key] }))
          break
        case 'whiteSpace':
          style.push(css({ whiteSpace: props[key] }))
          break
        case 'letterSpacing':
          style.push(css({ letterSpacing: props[key] }))
          break
        case 'flex':
          style.push(css({ flex: props[key] }))
          break
        case 'zIndex':
          style.push(css({ zIndex: props[key] }))
          break
        case 'image':
          style.push(css({ background: props[key] }))
          break
        case 'overflow':
          style.push(css({ overflow: props[key] }))
          break
        case 'overflowX':
          style.push(css({ overflowX: props[key] }))
          break
        case 'overflowY':
          style.push(css({ overflowY: props[key] }))
          break
        case 'boxShadow':
          style.push(css({ boxShadow: props[key] }))
          break
        case 'transform':
          style.push(css({ transform: props[key] }))
          break
        default:
          break
      }
    }
    // 3. Apply customStyle passed down as this.props.style
    style.push(customStyle)
    style.push(customClassName)

    // 4. Apply mediaquery style passed down as maxXSStyle, maxSMStyle, maxMDStyle, maxLGStyle, maxXLStyle
    const { matchXS, matchSM, matchMD, matchLG, matchXL } = this.state
    if (matchXL) {
      style.push(maxXLStyle)
    } else if (matchLG) {
      style.push(maxLGStyle)
    } else if (matchMD) {
      style.push(maxMDStyle)
    } else if (matchSM) {
      style.push(maxSMStyle)
    } else if (matchXS) {
      style.push(maxXSStyle)
    }

    return (
      <div className={css(style)} onClick={!disabled ? onClick : undefined}>
        {children}
      </div>
    )
  }
  _onMatchXS = ({ matches }) => {
    this.setState({ matchXS: matches })
  }
  _onMatchSM = ({ matches }) => {
    this.setState({ matchSM: matches })
  }
  _onMatchMD = ({ matches }) => {
    this.setState({ matchMD: matches })
  }
  _onMatchLG = ({ matches }) => {
    this.setState({ matchLG: matches })
  }
  _onMatchXL = ({ matches }) => {
    this.setState({ matchXL: matches })
  }
}

const styles = {
  baseStyle: css({
    fontSize: 18,
  }),
  flex: css({
    display: 'flex',
  }),
  block: css({
    display: 'block',
  }),
  inlineBlock: css({
    display: 'inline-block',
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  column: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  fill: css({
    flex: 1,
  }),
  wrap: css({
    flexWrap: 'wrap',
  }),
  absoluteFill: css({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }),
  relative: css({
    position: 'relative',
  }),
  absolute: css({
    position: 'absolute',
  }),
  fixed: css({
    position: 'fixed',
  }),
  selfCenter: css({
    alignSelf: 'center',
  }),
  selfStart: css({
    alignSelf: 'flex-start',
  }),
  selfEnd: css({
    alignSelf: 'flex-end',
  }),
  selfStretch: css({
    alignSelf: 'stretch',
  }),
  center: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  alignCenter: css({
    alignItems: 'center',
  }),
  alignStart: css({
    alignItems: 'flex-start',
  }),
  alignEnd: css({
    alignItems: 'flex-end',
  }),
  alignStretch: css({
    alignItems: 'stretch',
  }),
  justifyCenter: css({
    justifyContent: 'center',
  }),
  justifyBetween: css({
    justifyContent: 'space-between',
  }),
  justifyAround: css({
    justifyContent: 'space-around',
  }),
  justifyEvenly: css({
    justifyContent: 'space-evenly',
  }),
  justifyEnd: css({
    justifyContent: 'flex-end',
  }),
  pointer: css({
    cursor: 'pointer',
  }),
  amatic: css({
    fontFamily: 'amatic',
  }),
  antonio: css({
    fontFamily: 'antonio',
  }),
  blowbrush: css({
    fontFamily: 'blowbrush',
  }),
  chalk: css({
    fontFamily: 'chalk',
  }),
  dafoe: css({
    fontFamily: 'dafoe',
  }),
  dolce: css({
    fontFamily: 'dolce',
  }),
  din: css({
    fontFamily: 'din',
  }),
  edo: css({
    fontFamily: 'edo',
  }),
  futura: css({
    fontFamily: 'futura',
  }),
  hemi: css({
    fontFamily: 'hemi',
  }),
  raleway: css({
    fontFamily: 'raleway',
  }),
  roboto: css({
    fontFamily: 'roboto',
  }),
  textDisabled: css({
    color: 'rgba(255,255,255,0.6)',
  }),
  textLeft: css({
    textAlign: 'left',
  }),
  textCenter: css({
    textAlign: 'center',
  }),
  textRight: css({
    textAlign: 'right',
  }),
  capitalize: css({
    textTransform: 'capitalize',
  }),
  uppercase: css({
    textTransform: 'uppercase',
  }),
  lowercase: css({
    textTransform: 'lowercase',
  }),
  superhero: css({
    fontSize: 50,
  }),
  hero: css({
    fontSize: 40,
  }),
  h1: css({
    fontSize: 36,
  }),
  h2: css({
    fontSize: 24,
  }),
  h3: css({
    fontSize: 20,
  }),
  h4: css({
    fontSize: 18,
  }),
  h5: css({
    fontSize: 16,
  }),
  h6: css({
    fontSize: 14,
  }),
  h7: css({
    fontSize: 12,
  }),
  xBold: css({
    fontWeight: '900',
  }),
  bold: css({
    fontWeight: '700',
  }),
  thin: css({
    fontWeight: '300',
  }),
  xThin: css({
    fontWeight: '100',
  }),
  black: css({
    color: 'black',
  }),
  white: css({
    color: 'white',
  }),
  truncate: css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  hover: css({
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'translateY(-2px)',
    },
  }),
  highlight: css({
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      backgroundColor: '#36B7EE',
    },
  }),
  pulsate: css({
    cursor: 'pointer',
    animation: 'pulsate 1s infinite',
    '@keyframes pulsate': {
      '0%': {
        transform: 'scaleX(1) scaleY(1)',
      },
      '50%': {
        transform: 'scaleX(1.03) scaleY(1.03)',
      },
    },
  }),
}
