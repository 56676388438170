import createRestaurantModel from './restaurantModel'

export default (restaurantId, defaultLocationId, tableId = '', subscribe = true) => ({
  config: {
    models: {
      restaurant: createRestaurantModel(restaurantId, defaultLocationId, tableId),
    },
  },
  onStoreCreated(store) {
    if (subscribe) {
      store.dispatch({ type: 'restaurant/subscribeRestaurant' })
    }
  },
})
