import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DownloadAppButton from './DownloadAppButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          maxHeight="750px"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
          justifyStart
        >
          <CFView textCenter column mt="13%" w="100%" h="80vh">
            <CFView column center>
              <CFImage src={mobileHeroText} w="90%" alt="Promotions" />
            </CFView>
            <CFView mt="25px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          maxWidth="1400px"
          image={`url(${hero}) right / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignEnd
          pr="5%"
        >
          <CFView h="100vh" maxHeight="700px" column justifyCenter alignStart>
            <CFImage src={heroText} w="100%" maxWidth="680px" alt="heroText" />
            <CFView mt="5%" pulsate ml="12px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
