import React, { Component } from 'react'
import { css } from 'emotion'

export default class CFImage extends Component {
  render() {
    const {
      alt,
      src,
      children,
      style: customStyle,
      className: customClassName,
      ...props
    } = this.props

    // 1. Apply base style
    const style = [styles.baseStyle]

    // 2. Apply prop styles
    const keys = Object.keys(props)
    for (const key of keys) {
      if (props[key] === true && styles[key]) {
        style.push(styles[key])
      }

      switch (key) {
        case 'mh':
          style.push(css({ marginLeft: props[key], marginRight: props[key] }))
          break
        case 'mv':
          style.push(css({ marginTop: props[key], marginBottom: props[key] }))
          break
        case 'mt':
          style.push(css({ marginTop: props[key] }))
          break
        case 'mb':
          style.push(css({ marginBottom: props[key] }))
          break
        case 'ml':
          style.push(css({ marginLeft: props[key] }))
          break
        case 'mr':
          style.push(css({ marginRight: props[key] }))
          break
        case 'm':
          style.push(css({ margin: props[key] }))
          break
        case 'ph':
          style.push(css({ paddingLeft: props[key], paddingRight: props[key] }))
          break
        case 'pv':
          style.push(css({ paddingTop: props[key], paddingBottom: props[key] }))
          break
        case 'pt':
          style.push(css({ paddingTop: props[key] }))
          break
        case 'pb':
          style.push(css({ paddingBottom: props[key] }))
          break
        case 'pl':
          style.push(css({ paddingLeft: props[key] }))
          break
        case 'pr':
          style.push(css({ paddingRight: props[key] }))
          break
        case 'p':
          style.push(css({ padding: props[key] }))
          break
        case 'w':
          style.push(css({ width: props[key] }))
          break
        case 'maxWidth':
          style.push(css({ maxWidth: props[key] }))
          break
        case 'minWidth':
          style.push(css({ minWidth: props[key] }))
          break
        case 'h':
          style.push(css({ height: props[key] }))
          break
        case 'minHeight':
          style.push(css({ minHeight: props[key] }))
          break
        case 'maxHeight':
          style.push(css({ maxHeight: props[key] }))
          break
        case 'bg':
          style.push(css({ backgroundColor: props[key] }))
          break
        case 'br':
          style.push(css({ borderRadius: props[key] }))
          break
        case 'bc':
          style.push(css({ border: 'solid', borderColor: props[key] }))
          break
        case 'bw':
          style.push(css({ borderWidth: props[key] }))
          break
        case 'bbw':
          style.push(css({ borderBottomWidth: props[key] }))
          break
        case 'btw':
          style.push(css({ borderTopWidth: props[key] }))
          break
        case 'blw':
          style.push(css({ borderLeftWidth: props[key] }))
          break
        case 'brw':
          style.push(css({ borderRightWidth: props[key] }))
          break
        case 'of':
          style.push(css({ objectFit: props[key] }))
          break
        case 'op':
          style.push(css({ objectPosition: props[key] }))
          break
        case 'top':
          style.push(css({ top: props[key] }))
          break
        case 'bottom':
          style.push(css({ bottom: props[key] }))
          break
        case 'left':
          style.push(css({ left: props[key] }))
          break
        case 'right':
          style.push(css({ right: props[key] }))
          break
        case 'flex':
          style.push(css({ flex: props[key] }))
          break
        case 'zIndex':
          style.push(css({ zIndex: props[key] }))
          break
        case 'boxShadow':
          style.push(css({ boxShadow: props[key] }))
          break
        default:
          break
      }
    }

    // 3. Apply customStyle passed down as this.props.style
    style.push(customStyle)
    style.push(customClassName)

    return (
      <img className={css(style)} alt={alt} src={src}>
        {children}
      </img>
    )
  }
}

const styles = {
  baseStyle: css({}),
  cover: css({
    objectFit: 'cover',
  }),
  contain: css({
    objectFit: 'contain',
  }),
  relative: css({
    position: 'relative',
  }),
  absolute: css({
    position: 'absolute',
  }),
  fixed: css({
    position: 'fixed',
  }),
  selfCenter: css({
    alignSelf: 'center',
  }),
  hover: css({
    cursor: 'pointer',
    transition: 'all .2s',
    '&:hover': {
      transform: 'translateY(-2px)',
    },
  }),
}
