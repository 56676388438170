import React from 'react'

export default class CFRenderless extends React.Component {
  componentDidMount() {
    this.props.componentDidMount && this.props.componentDidMount()
    if (this.props.setTimeout) {
      this.setTimeoutId = setTimeout(this.props.setTimeout, this.props.setTimeoutms || 0)
    }
    if (this.props.setInterval) {
      if (this.props.setIntervalCallOnMount) {
        this.props.setInterval()
      }
      this.setIntervalId = setInterval(this.props.setInterval, this.props.setIntervalms || 0)
    }
  }
  componentDidUpdate(...param) {
    this.props.componentDidUpdate && this.props.componentDidUpdate(...param)
  }
  componentWillUnmount() {
    this.props.componentWillUnmount && this.props.componentWillUnmount()
    clearTimeout(this.setTimeoutId)
    clearInterval(this.setIntervalId)
  }
  render() {
    return this.props.children || null
  }
}
