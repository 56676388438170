import React, { Component } from 'react'
import { connect } from 'react-redux'
import NotificationSystem from 'react-notification-system'

class Notification extends Component {
  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    const { message, level, position = 'bc' } = newProps.notification
    this.notificationSystem.addNotification({
      message,
      level,
      position,
    })
  }
  render() {
    return <NotificationSystem ref="notificationSystem" />
  }
}

function mapState(state) {
  return {
    notification: state.notification,
  }
}

export default connect(mapState)(Notification)
