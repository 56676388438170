import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { Hero404 } from 'images'

export default () => {
  return (
    <CFView h="100vh" w="100vw" center>
      <MobileScreen>
        <CFImage src={Hero404} w="100%" alt="404 Page" />
      </MobileScreen>
      <DefaultScreen>
        <CFImage src={Hero404} w="50%" alt="404 Page" />
      </DefaultScreen>
    </CFView>
  )
}
