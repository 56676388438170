import React from 'react'
import { css } from 'emotion'

export default props => {
  const { children, disabled, newTab, ...rest } = props
  return (
    <a
      className={styles.link}
      disabled={disabled}
      style={disabled ? { opacity: 0.5 } : {}}
      target={newTab ? '_blank' : null}
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </a>
  )
}

const styles = {
  link: css({
    textDecoration: 'none',
  }),
}
