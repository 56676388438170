import React, { Component } from 'react'
import { css } from 'emotion'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root')

export default class CFModal extends Component {
  render() {
    const { children, isOpen, onRequestClose } = this.props
    return (
      <ReactModal
        shouldCloseOnOverlayClick={true}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: 0,
            backgroundColor: 'transparent',
            transform: 'translate(-50%, -50%)',
          },
        }}
        contentLabel="Modal"
        overlayClassName={styles.overlay}
        bodyOpenClassName={styles.body}
      >
        {children}
      </ReactModal>
    )
  }
}

const styles = {
  overlay: css({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,.5)',
    zIndex: 99,
  }),
  body: css({
    overflowY: 'hidden',
  }),
}
