import React from 'react'
import { CFView } from 'components'
import { COLOR } from 'styles'

export default ({ children, title, LeftButton, ...props }) => {
  return (
    <CFView
      br="5px"
      bg={COLOR.lightOpacity}
      boxShadow="1px 1px 3px rgba(0,0,0,.6)"
      overflow="hidden"
      relative
      {...props}
    >
      <CFView absolute top="22px" left="40px">
        {LeftButton}
      </CFView>
      {title && (
        <CFView h2 bold textCenter mb="20px">
          {title}
        </CFView>
      )}
      {children}
    </CFView>
  )
}
