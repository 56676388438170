import { init } from '@rematch/core'
import { plugins } from 'cf-core'
import { plugin as restaurantPlugin } from 'cf-models-restaurant'
import ENV from 'ENV'

export const store = init({
  plugins: [
    plugins.actions,
    restaurantPlugin(ENV.RESTAURANT_ID, ENV.LOCATION_ID),
  ],
})

export const { dispatch, getState } = store
